<template>
  <div class="bannerTop">
    <div class="star">
      <img :src="$imgs['home/star.png']" alt="" />
      <span>{{ $t('Index.全民免月费最多5个月！') }}</span>
    </div>
    <div class="reservation" @click="goUrl('https://wa.me/message/AIWYWQB37JLCD1')">
      <img :src="$imgs['home/whatsapp@2x.png']" alt="" />
      <i>{{ $t('Index.马上登记') }}</i>
    </div>
  </div>
  <el-carousel class="banner" :class="locale" trigger="click" arrow="always" :autoplay="true" :interval="5000">
    <el-carousel-item>
      <div class="item">
        <div class="h1Div none">
          <h1>
            {{ $t('Index./ 属于年青人的') }}<span>{{ $t('Index.5G通讯服务') }}</span>
          </h1>
        </div>
        <div class="h4Div none">{{ $t('Index.全方位应援你日常通讯与生活') }}</div>
        <div class="register none">
          <div @click="goUrl(`https://www.hk.chinamobile.com/${locale}/home/plan/slash`)">
            <span>{{ $t('Index.立即登记') }}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 20 10">
              <path d="M17922.25,107h-17l1-1h14.4l-5.4-9h4l6,10Z" transform="translate(-17905.25 -97)" fill="#fff" />
            </svg>
          </div>
        </div>
        <div class="oUl">
          <div class="flex">
            <div class="oLi">
              <b>12/24</b>
              {{ $t('Index.12个月短约') }}
            </div>
            <div class="oLi">
              <b>{{ $t('Index.学生') }}</b>
              {{ $t('Index.免最多') }}
              <b>{{ $t('Index.年青人') }}</b>
              {{ $t('Index.个月月费') }}
            </div>
            <div class="oLi">
              {{ $t('Index.免') }}
              <b>$18</b>
              {{ $t('Index.行政费') }}
            </div>
          </div>
          <div class="tips">{{ $t('Index.banner备注') }}</div>
        </div>
      </div>
    </el-carousel-item>
  </el-carousel>
  <div class="plan">
    <div class="title">
      <h2><span>／</span>{{ $t('Index.还在选择学生Plan或5G高价Plan?') }}</h2>
    </div>
    <div class="subtitle" v-html="$t('Index.s/ash为你提供简单3个计划')"></div>
    <Plan></Plan>
  </div>
  <div class="cont_bg">
    <div class="title">
      <!-- <span>／</span> s/ash有咩咁正? -->
    </div>
    <div class="whySelect"><WhySelect></WhySelect></div>
    <div class="title bgBlue pb16" v-html="$t('Index.／全心考虑你的需要')"></div>
    <Advantage></Advantage>
  </div>
  <Rule :title="$t('Index.备注')" :detail="$t('Index.备注内容')"></Rule>
</template>
<script setup lang="ts">
import { Bell } from '@element-plus/icons-vue'
import Plan from './components/plan.vue'
import WhySelect from './components/whySelect.vue'
import Advantage from './components/advantage.vue'
import Rule from './components/rule.vue'
import { useI18n } from 'vue-i18n'
import { navigate } from 'vite-plugin-ssr/client/router'
const { locale } = useI18n()
function goRouter(name: string) {
  navigate('/' + locale.value + name)
}
function goUrl(url) {
  window.location.href = url
}
</script>
<style scoped lang="less">
@width: 1920;
.title {
  text-align: center;
  font-size: 2.083vw;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  span {
    color: #00a7ff;
  }
}
.cont_bg {
  .title {
    padding-top: 142px;
  }
}
.blue {
  color: #00a7ff;
}
.bannerTop {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.645vw;
  width: 100%;
  background: #f4f49e;
  .star {
    display: flex;
    align-items: center;
    > img {
      width: 1.197vw;
      margin-right: 0.4vw;
    }
    > span {
      display: block;
      font-size: 1.0417vw;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
    }
  }
  > .reservation {
    margin-left: 1.5625vw;
    height: 1.979vw;
    cursor: pointer;
    padding: 0 1.197vw 0 1.5625vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(26, 213, 134, 1);
    border-radius: 5px 5px 5px 5px;
    img {
      width: 1.042vw;
      height: 1.042vw;
      margin-right: 8px;
    }
    > i {
      margin-right: 3px;
      font-style: normal;
      font-size: 1.0417vw;
      font-weight: 600;
      color: #fff;
    }
  }
}

::v-deep(.el-carousel__container) {
  height: (800/19.2vw);
}
.tc {
  .el-carousel__item {
    &:nth-of-type(1) {
      background: url('@/assets/imgs/home/banner2-bg-tc.png') no-repeat center center;
      background-size: cover;
    }
    &:nth-of-type(2) {
      background: url('@/assets/imgs/ambassador_recruitment/tc/banner.png') no-repeat center center;
      background-size: cover;
    }
  }
}
.sc {
  .el-carousel__item {
    &:nth-of-type(1) {
      background: url('@/assets/imgs/home/banner2-bg-sc.png') no-repeat center center;
      background-size: cover;
    }
    &:nth-of-type(2) {
      background: url('@/assets/imgs/ambassador_recruitment/sc/banner.png') no-repeat center center;
      background-size: cover;
    }
  }
}
.en {
  .el-carousel__item {
    &:nth-of-type(1) {
      background: url('@/assets/imgs/home/banner2-bg-en.png') no-repeat center center;
      background-size: cover;
    }
    &:nth-of-type(2) {
      background: url('@/assets/imgs/ambassador_recruitment/en/banner.png') no-repeat center center;
      background-size: cover;
    }
  }
}

.el-carousel__item {
  .item {
    height: 100%;
    width: 69vw;
    margin: 0 auto;
    position: relative;
    .h1Div {
      padding-top: 2.083vw;
      color: #00a7ff;
      font-size: 2.5vw;
      font-weight: 600;
      span {
        color: #fff;
      }
    }
    .h4Div {
      padding-top: 1.0417vw;
      font-size: 1.25vw;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.6);
    }
    .register {
      display: flex;
      align-items: center;
      > div {
        display: flex;
        align-items: center;
        padding-right: 2.084vw;
        padding-left: 6.25vw;
        cursor: pointer;
        margin-top: 1.5625vw;
        height: 3.541vw;
        line-height: 3.541vw;
        background: #1ad586;
        border-radius: 1.77vw;
        span {
          font-size: 1.041vw;
          font-weight: 600;
          color: #ffffff;
          margin-right: 54px;
        }
      }
    }
    .oUl {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 60px;
      margin: auto;
      .flex {
        margin: 8.072vw auto 0;
        display: flex;
        align-items: center;
        width: 60vw;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 1.25vw;
        padding: 1.8vw 0;
      }
      .oLi {
        line-height: 1.2;
        text-align: center;
        flex: 1;
        font-size: 1.2vw;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
        padding: 0 (30 / 19.2vw);
        b {
          font-size: 2vw;
          color: #1ad586;
        }
        &:nth-of-type(2) {
          position: relative;
          &::before {
            position: absolute;
            content: ' ';
            top: 0;
            left: 0px;
            width: 1.25vw;
            height: 100%;
            background: url('@/assets/imgs/home/border.png') no-repeat center center;
            background-size: contain;
          }
          &::after {
            position: absolute;
            content: ' ';
            top: 0;
            right: 0px;
            width: 1.25vw;
            height: 100%;
            background: url('@/assets/imgs/home/border.png') no-repeat center center;
            background-size: contain;
          }
        }
      }
      .tips {
        padding-top: 20px;
        font-weight: 400;
        font-size: (14/19.2vw);
        color: rgba(0, 0, 0, 0.4);
        text-align: center;
      }
    }
  }
}
.subtitle {
  text-align: center;
  font-size: 1.25vw;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  padding: 0.78125vw 0 4.6875vw;
}
.plan {
  padding: 5.208vw 0 0;
  background: url('@/assets/imgs/home/planBg.png') no-repeat center bottom;
  background-size: 100%;
}
@media screen and (max-width: 768px) {
  @width: 375;

  .plan {
    background: none;
  }
  .cont_bg {
    .title {
      padding-top: 0;
    }
  }
  .subtitle {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
  }
  .cont_bg {
    padding-top: 64px;
    background: url('@/assets/imgs/home/cont_bg.png') no-repeat center center;
    background-size: 100% 100%;
    padding-bottom: 60px;
  }
  .rule {
    padding: 0;
    margin-bottom: -20px;
    transform: translateY(-60px);
  }
  .title {
    font-size: 20px;
  }
  .pb16 {
    padding-bottom: 16px;
  }
  .bannerTop {
    overflow: hidden;
    width: 100%;
    height: 50px;
    justify-content: space-between;
    .star {
      margin-left: 16px;
      img {
        width: 21px;
        margin-right: 6px;
      }
      span {
        font-size: 12px;
      }
    }
    > .reservation {
      margin-right: 16px;
      padding: 0 10px;
      height: 30px;
      border-radius: 3px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 3px;
      }
      > i {
        white-space: nowrap;
        font-size: 12px;
      }
    }
  }
  ::v-deep(.el-carousel__container) {
    height: calc(100vh - 70px);
    background: none;
  }
  .tc {
    .el-carousel__item {
      &:nth-of-type(1) {
        background: url('@/assets/imgs/home/banner2_mobile_tc.png') no-repeat center top;
        background-size: cover;
      }
      &:nth-of-type(2) {
        background: url('@/assets/imgs/ambassador_recruitment/tc/banner_phone.png') no-repeat center top;
        background-size: cover;
      }
    }
  }
  .sc {
    .el-carousel__item {
      &:nth-of-type(1) {
        background: url('@/assets/imgs/home/banner2_mobile_sc.png') no-repeat center top;
        background-size: cover;
      }
      &:nth-of-type(2) {
        background: url('@/assets/imgs/ambassador_recruitment/sc/banner_phone.png') no-repeat center top;
        background-size: cover;
      }
    }
  }
  .en {
    .el-carousel__item {
      &:nth-of-type(1) {
        background: url('@/assets/imgs/home/banner2_mobile_en.png') no-repeat center top;
        background-size: cover;
      }
      &:nth-of-type(2) {
        background: url('@/assets/imgs/ambassador_recruitment/en/banner_phone.png') no-repeat center top;
        background-size: cover;
      }
    }
  }
  .el-carousel__item {
    .item {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      .h1Div {
        margin-top: 11px;
        font-size: 24px;
        font-weight: 600;
        padding: 32px 100px 16px;
        color: #ffffff;
      }
      .h4Div {
        font-size: 16px;
      }
      .register {
        margin-top: 32px;
        > div {
          padding: 0;
          margin: 0 auto;
          height: 38px;
          line-height: 38px;
          background: #1ad586;
          width: (166 / @width * 100%);
          border-radius: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            margin-right: 17px;
            font-size: 16px;
            padding: 0;
          }
        }
      }
      .oUl {
        position: absolute;
        bottom: 40px;
        left: 0;
        right: 0;
        .flex {
          width: (343 / @width * 100%);
          margin: 0 auto;
          min-height: 70px;
        }
        .oLi {
          font-size: 12px;
          padding: 0 5px;
          .register {
            width: 166px;
            height: 38px;
            > div {
              line-height: a;
            }
          }
          b {
            font-size: 20px;
          }
          &:nth-of-type(2) {
            ::before,
            ::after {
              width: 12px;
              height: 28px;
            }
          }
        }
        .tips {
          font-size: 10px;
          padding: 5px 5px 0;
        }
      }
    }
  }
  @bgBlue: #ebfbff;
  .bgBlue {
    background: @bgBlue;
  }
  .feePackage {
    padding: 0;
    .subtitle {
      font-size: 12px;
    }
  }
}
</style>
<style>
.none {
  display: none !important;
}
.blue {
  color: #00a7ff;
  font-weight: 600;
}
.red {
  color: #eb6ea5;
}
.size20 {
  font-size: 1.042vw;
}
@media screen and (max-width: 768px) {
  .size20 {
    font-size: 20px;
  }
}
</style>
