<template>
  <div class="advantage hidden-xs-only">
    <div class="item">
      <div class="introduce">
        <em>{{ $t('Index.为你提供比其他网络商') }}</em>
        <ul>
          <li v-html="$t('Index.更抵的价钱')"></li>
          <li v-html="$t('Index.更快的网络')"></li>
          <li v-html="$t('Index.更多的数据')"></li>
        </ul>
        <span class="yellow">{{ $t('Index.5G月费计划低至') }}<b class="blue">$98</b></span>
      </div>
      <div class="operate">
        <span>{{ $t('Index.／考虑你的预算') }}</span>
        <b>
          <h3>{{ $t('Index.优惠月费') }}</h3>
        </b>
        <div class="btn" @click="goUrl(`https://www.hk.chinamobile.com/${locale}/home/plan/slash`)">{{ $t('Index.了解更多') }}</div>
      </div>
      <div class="oImg">
        <img :src="$imgs['home/home_people1.png']" alt="" />
      </div>
    </div>
    <div class="item">
      <div class="oImg">
        <img :src="$imgs['home/home_people2.png']" alt="" />
      </div>
      <div class="operate">
        <span>{{ $t('Index.／考虑你的通讯需要') }}</span>
        <b>
          <h3>{{ $t('Index.极速网络') }}</h3>
        </b>
        <div class="btn" @click="goRouter('/slashintro#network')">{{ $t('Index.了解更多') }}</div>
      </div>
      <div class="introduce">
        <div class="plp">
          <p>
            {{ $t('Index.明白你不想再Load了个寂寞或者最怕月尾用光数据') }}
          </p>
          <p>{{ $t('Index.只为你提供') }}</p>
          <div class="highlight">
            <b class="yellow blue">{{ $t('Index.【最优秀的5G网络】') }}</b>
          </div>
          <p>powered by CMHK</p>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="introduce">
        <p>{{ $t('Index.为你提供') }}<br /><span class="yellow" v-html="$t('Index.【24小时线上客服支撑】')"> </span></p>
        <p>{{ $t('Index.用心关注你每一个问题') }}</p>
        <p v-html="$t('Index.尽快解答你的疑难')"></p>
      </div>
      <div class="operate">
        <span>{{ $t('Index.／考虑你的心情') }}</span>
        <b>
          <h3>{{ $t('Index.贴心服务') }}</h3>
        </b>
      </div>
      <div class="oImg">
        <img :src="$imgs['home/home_people3.png']" alt="" />
      </div>
    </div>
  </div>
  <div class="advantage hidden-sm-and-up">
    <div class="item">
      <div class="operate">
        <span>{{ $t('Index.／考虑你的预算') }}</span>
        <div class="oImg">
          <img :src="$imgs['home/home_people1.png']" alt="" />
        </div>
        <b>
          <h3>{{ $t('Index.优惠月费') }}</h3>
        </b>
      </div>
      <div class="introduce">
        <em>{{ $t('Index.为你提供比其他网络商') }}</em>
        <ul>
          <li v-html="$t('Index.更抵的价钱')"></li>
          <li v-html="$t('Index.更快的网络')"></li>
          <li v-html="$t('Index.更多的数据')"></li>
        </ul>
        <div class="highlight">
          <span>{{ $t('Index.5G月费计划低至') }}<b>$98</b></span>
        </div>
      </div>
      <div class="btn" @click="goUrl(`https://www.hk.chinamobile.com/${locale}/home/plan/slash`)">{{ $t('Index.了解更多') }}</div>
    </div>
    <div class="item">
      <div class="operate">
        <span>{{ $t('Index.／考虑你的通讯需要') }}</span>
        <div class="oImg">
          <img :src="$imgs['home/home_people2.png']" alt="" />
        </div>
        <b>
          <h3>{{ $t('Index.极速网络') }}</h3>
        </b>
      </div>
      <div class="introduce">
        <em>{{ $t('Index.明白你不想再Load了个寂寞或者最怕月尾用光数据') }}</em>
        <div class="highlight">
          <b>{{ $t('Index.【最优秀的5G网络】') }}</b>
        </div>
        <em>powered by CMHK</em>
      </div>
      <div class="btn" @click="goRouter('/slashintro#network')">{{ $t('Index.了解更多') }}</div>
    </div>
    <div class="item">
      <div class="operate">
        <span>{{ $t('Index.／考虑你的心情') }}</span>
        <div class="oImg">
          <img :src="$imgs['home/home_people3.png']" alt="" />
        </div>
        <b>
          <h3>{{ $t('Index.贴心服务') }}</h3>
        </b>
      </div>
      <div class="introduce">
        <em>{{ $t('Index.为你提供') }}</em>
        <div class="highlight">
          <span class="yellow blue" v-html="$t('Index.【24小时线上客服支撑】')"></span>
        </div>
        <em>{{ $t('Index.用心关注你每一个问题') }}</em>
        <em v-html="$t('Index.尽快解答你的疑难')"></em>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { navigate } from 'vite-plugin-ssr/client/router'
const { locale } = useI18n()
function goRouter(name: string) {
  navigate('/' + locale.value + name)
}
function goUrl(url: string) {
  window.location.href = url
}
</script>
<style scoped lang="less">
@width: 1920;
.advantage {
  margin-top: 1.5625vw;
  padding-bottom: 1.0416vw;
  .item {
    width: (1224 / @width * 100%);
    margin: 0 auto 1.5625vw;
    background: white;
    border-radius: 1.25vw;
    border: 2px solid #f3faff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 (50 / @width * 100%);
    .plp {
      padding-left: 70px;
    }
    .introduce {
      flex: 1;
      p {
        display: block;
        font-size: 1.0416vw;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.8);
        line-height: 2.0833vw;
      }
      em {
        display: block;
        font-style: normal;
        font-size: 1.0416vw;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
      }
      .red {
        color: #eb6ea5;
      }
      ul {
        margin-bottom: 10px;
        li {
          list-style: none;
          font-size: 1.0416vw;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.8);
          position: relative;
          padding-left: 1.1458vw;
          padding-top: 0.625vw;
          &::before {
            position: absolute;
            left: 0;
            bottom: 0px;
            margin: auto;
            width: 0.625vw;
            height: 1.09375vw;
            content: '';
            background: url('@/assets/imgs/home/planLi-activity.png') no-repeat center center;
            background-size: 100%;
          }
        }
      }
      .yellow {
        padding: 0 5px;
        font-size: 1.0416vw;
        display: table;
        height: 2.0833vw;
        background: #f0fc98;
      }
      .blue {
        color: #00a7ff;
        font-size: 1.6667vw;
      }
      .highlight {
        margin-top: 0.2083vw;
        span {
          padding: 0.4166vw 0.2083vw;
          font-size: 1.0416vw;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.8);
          background: #f0fc98;
          b {
            font-size: 1.6667vw;
            font-weight: 600;
            color: #00a7ff;
          }
        }
      }
    }
    .operate {
      flex: 1;
      text-align: center;
      > span {
        color: rgba(0, 0, 0, 0.8);
        font-size: 1.4583vw;
        display: block;
      }
      > b {
        display: block;
        font-size: 1.6667vw;
        font-weight: 600;
        color: #00a7ff;
      }
      > .btn {
        margin: 1.875vw auto 0;
        width: 13.542vw;
        height: 2.7083vw;
        font-size: 1.01vw;
        line-height: 2.7083vw;
        background: url('@/assets/imgs/home/slash_arrow@2x.png') no-repeat 9.675vw center, linear-gradient(135deg, #00a7ff 0%, #00ceff 100%);
        background-size: auto 1.25vw;
        border-radius: 0.4166vw;
        color: white;
        cursor: pointer;
      }
    }
    .oImg {
      flex: 1;
      width: 41.0416vw;
      height: 18.2291vw;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  @width: 375;
  @bgBlue: #ebfbff;
  .advantage {
    margin-top: 0;
    padding-top: 0;
    .item {
      width: (343 / @width * 100%);
      border-radius: 24px 24px 24px 24px;
      display: block;
      padding: 0;
      padding-bottom: 20px;
      text-align: center;
      margin-bottom: 16px;
      .operate {
        span {
          font-size: 16px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.8);
          line-height: 40px;
          padding: 24px 5px 4px;
        }
        .oImg {
          width: (240 / @width * 100%);
          margin: 0 auto;
          height: auto;
        }
        b {
          font-size: 20px;
          font-weight: 600;
          color: #00a7ff;
          line-height: 40px;
        }
      }
      .introduce {
        em {
          font-size: 16px;
        }
        ul {
          li {
            font-size: 16px;
            line-height: 26px;
            &::before {
              display: none;
            }
          }
        }
        .highlight {
          margin: 8px auto;
          display: table;
          padding: 0 10px;
          background: #f0fc98;
          > b {
            display: block;
            color: #00a7ff;
            font-size: 32px;
          }
          span {
            display: block;
            height: auto;
            margin: 0 auto;
            padding: 0;
            font-size: 16px;
            b {
              color: #00a7ff;
              font-size: 32px;
            }
          }
        }
      }
      .btn {
        width: (192 / @width * 100%);
        margin: 24px auto 0;
        height: 44px;
        line-height: 44px;
        border-radius: 8px;
        font-size: 16px;
        color: #fff;
        background: linear-gradient(135deg, #00a7ff 0%, #00ceff 100%);
      }
    }
  }
}
</style>
